class StickyCardIT extends BATComponent {
	/* Lifecycle event callbacks */
	beforeLoad() {
		// console.log('BlurbCard before load');
	}

	afterLoad() {
		// console.log('BlurbCard After load');
		this.$el = $(this);

		/*const stickyEl = this.$el.find('.bat-sticky-card-gloit');
		if (stickyEl) {
			UIkit.sticky(stickyEl, {
				start: 0,
				position: 'bottom',
				animation: 'uk-animation-slide-bottom'
			});
		}*/
	}

	beforeUpdate() {
		// console.log('BlurbCard before update');
	}

	afterUpdate() {
		// console.log('BlurbCard after update');
	}

	unload() {
		// console.log('BlurbCard after unload');
	}
}

!customElements.get('bat-card-gloitsticky') &&
	customElements.define('bat-card-gloitsticky', StickyCardIT);
